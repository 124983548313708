import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import {
  useToast,
  Text,
  Stack,
  Box,
  Select,
  Divider,
  Spinner,
  Button,
  Icon,
  useDisclosure,
  ModalFooter,
  ModalBody,
  ModalHeader,
  FormErrorMessage,
  Input,
  Modal,
  FormControl,
  ModalOverlay,
  ModalContent,
  Flex,
  Switch,
  InputGroup,
  InputRightAddon,
  Image,
  Badge,
  Textarea,
} from '@chakra-ui/core';
import { Link, useParams } from 'react-router-dom';
import actions from './actions';
import { convertTZ, showErrorToast, showSuccessToast } from './utils';
import { useForm } from 'react-hook-form';
import ReactS3Uploader from 'react-s3-uploader';

const ZipClass = () => {
  const [zipClass, setZipClass] = useState({});
  const [teacherOptions, setTeacherOptions] = useState([]);
  const [questionModal, setQuestionModal] = useState(false);
  const [picturePollModal, setPicturePollModal] = useState(false);

  const [timeSlotModal, setTimeSlotModal] = useState(false);
  const [presentationModal, setPresentationModal] = useState(false);
  const [gameModal, setGameModal] = useState(false);
  const [rewardModal, setRewardModal] = useState(false);

  const [slidesUploadProgress, setSlidesUploadProgress] = useState(0);
  const [virtualBackgroundUploadProgress, setVirtualBackgroundUploadProgress] = useState(0);
  const [posterUploadProgress, setPosterUploadProgress] = useState(0);
  const [pollUploadProgress, setPollUploadProgress] = useState([0, 0, 0, 0]);
  const [pollUploadUrls, setPollUploadUrls] = useState([null, null, null, null]);

  const [gameUploadProgress, setGameUploadProgress] = useState(0);
  const [gameStart, setGameStart] = useState(null);
  const [gameEnd, setGameEnd] = useState(null);
  const [learningGoal, setLearningGoal] = useState('');
  const [submittingLearningGoal, setSubmittingLearningGoal] = useState(false);

  const [correctAnswerIndex, setCorrectAnswerIndex] = useState(null);
  const [hideAnswerOptions, setHideAnswerOptions] = useState(false);

  const [loading, setLoading] = useState(false);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { handleSubmit, errors, register, formState } = useForm();
  const { unit_id, class_id } = useParams();
  const toast = useToast();
  const gameUploadRef = useRef();
  const textAreaRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      await fetchItem();
    };

    fetchData();
  }, []);

  const fetchItem = async () => {
    setLoading(true);
    try {
      const { result: _ = {} } = await actions.fetchClass(unit_id, class_id);
      console.log(_);
      const { class: _zipClass = {}, teacherOptions: _teacherOptions = [] } = _;
      setZipClass(_zipClass);
      setTeacherOptions(_teacherOptions);
    } catch (err) {
      showErrorToast(toast, err.message);
    }
    setSlidesUploadProgress(0);
    setGameUploadProgress(0);
    setVirtualBackgroundUploadProgress(0);
    setPosterUploadProgress(0);
    setGameModal(false);
    setLoading(false);
  };

  const validateId = value => {
    let error;
    if (!value) {
      error = `Required`;
    }
    return error || true;
  };

  const onCreatePresentation = async (data = {}) => {
    try {
      const res = await actions.createPresentation(unit_id, class_id, data);
      await fetchItem();
      setPresentationModal(false);
      showSuccessToast(toast, 'Presentation created ✅');
    } catch (err) {
      showErrorToast(toast, err.message);
    }
  };

  const onCreateTimeSlot = async (data = {}) => {
    try {
      const res = await actions.createTimeSlot(unit_id, class_id, data);
      await fetchItem();
      onClose();
      console.log(res);
      showSuccessToast(toast, 'Time slot created ✅');
    } catch (err) {
      showErrorToast(toast, err.message);
    }
  };

  const onCreateReward = async (data = {}) => {
    try {
      const res = await actions.createReward(unit_id, class_id, data);
      await fetchItem();
      setRewardModal(null);
      console.log(res);
      showSuccessToast(toast, 'Reward created ✅');
    } catch (err) {
      showErrorToast(toast, err.message);
    }
  };

  const onCreateQuestion = async (data = {}) => {
    const {
      title,
      answer1,
      share1,
      answer2,
      share2,
      answer3,
      share3,
      answer4,
      share4,
      start,
      end,
      reveal,
      poll_type,
    } = data;
    try {
      const answers = [];
      if (answer1 || pollUploadUrls[0])
        answers.push({
          title: answer1,
          share: share1,
          url: pollUploadUrls[0],
          isCorrect: correctAnswerIndex === 0 ? true : undefined,
        });
      if (answer2 || pollUploadUrls[1])
        answers.push({
          title: answer2,
          share: share2,
          url: pollUploadUrls[1],
          isCorrect: correctAnswerIndex === 1 ? true : undefined,
        });
      if (answer3 || pollUploadUrls[2])
        answers.push({
          title: answer3,
          share: share3,
          url: pollUploadUrls[2],
          isCorrect: correctAnswerIndex === 2 ? true : undefined,
        });
      if (answer4 || pollUploadUrls[3])
        answers.push({
          title: answer4,
          share: share4,
          url: pollUploadUrls[3],
          isCorrect: correctAnswerIndex === 3 ? true : undefined,
        });

      await actions.createQuestion(unit_id, class_id, {
        title,
        answers,
        start,
        reveal,
        end,
        hide_answer_options: hideAnswerOptions,
        poll_type,
      });
      await fetchItem();
      setQuestionModal(false);
      setPicturePollModal(false);
      setPollUploadProgress([0, 0, 0, 0]);
      setPollUploadUrls([null, null, null, null]);
      setCorrectAnswerIndex(null);
      showSuccessToast(toast, 'Poll created ✅');
    } catch (err) {
      showErrorToast(toast, err.message);
    }
  };

  const onCreateGame = async (data = {}) => {
    try {
      if (gameUploadRef && gameUploadRef.current) gameUploadRef.current.uploadFile();
    } catch (err) {
      showErrorToast(toast, err.message);
    }
  };

  const onLearningGoalChange = e => {
    let inputValue = e.target.value;
    setLearningGoal(inputValue);
  };
  const submitLearningGoal = async () => {
    try {
      setSubmittingLearningGoal(true);
      console.log(learningGoal);
      await actions.updateClass(class_id, { learning_goal: learningGoal });
      await fetchItem();

      if (textAreaRef && textAreaRef.current) {
        textAreaRef.current.value = '';
        setLearningGoal('');
      }

      setSubmittingLearningGoal(false);
    } catch (err) {
      showErrorToast(toast, err.message);
      setSubmittingLearningGoal(false);
    }
  };

  const renderItem = (item = {}) => {
    const { title, date, items = [], id } = item;

    return (
      <Box key={id} border="1px" borderRadius="4px" p={3} borderColor="gray.200">
        <Stack spacing={1} mr={2}>
          <Box fontWeight="bold">{title}</Box>
          <Box fontSize="sm">ID: {id}</Box>
          <Stack spacing={2}>
            {items.map(i => {
              return (
                <a style={{ textDecoration: 'underline' }} key={i.id} href={i.url}>
                  {i.title}
                </a>
              );
            })}
          </Stack>
          <Box fontSize="xs" color="gray.500">
            {date}
          </Box>
        </Stack>
      </Box>
    );
  };

  const deleteQuestion = async id => {
    setLoading(true);
    try {
      await actions.deleteResource(unit_id, class_id, id);
      await fetchItem();
      showSuccessToast(toast, 'Resource Deleted ✅');
    } catch (err) {
      showErrorToast(toast, err.message);
    }
    setLoading(false);
  };

  const renderPoll = (item = {}) => {
    const { title, timestamp, start, reveal, end, id, answers = [] } = item;

    return (
      <Box key={id} border="1px" borderRadius="4px" p={3} borderColor="gray.200">
        <Text fontSize="sm">ID: {id}</Text>
        <Flex justifyContent="center">
          <Text fontSize="sm">
            Start: <b>{start}</b>
          </Text>
          <Text ml={2} fontSize="sm">
            Reveal: <b>{reveal}</b>
          </Text>
          <Text ml={2} fontSize="sm">
            End: <b>{end}</b>
          </Text>
        </Flex>
        <Text fontSize="sm">Created At: {convertTZ(timestamp, { showTimeZone: true })}</Text>

        <Text mt={2} fontWeight="bold">
          {title}
        </Text>
        <Stack mt={2} spacing={2}>
          {answers.map(i => {
            return (
              <Text fontWeight={i.isCorrect ? 'bold' : 'normal'} key={i.title}>
                {i.title}
                {i.isCorrect ? <span style={{ marginLeft: '0.5em' }}>✅</span> : ''}
              </Text>
            );
          })}
        </Stack>
        <Divider />
        <Button
          isLoading={loading}
          onClick={() => deleteQuestion(id)}
          mt={2}
          bg="red.500"
          color="white"
          fontWeight="bold"
          w="100%"
        >
          Delete Question
        </Button>
      </Box>
    );
  };

  const renderReward = (item = {}) => {
    const { title, timestamp, start, id, type } = item;

    return (
      <Box key={id} border="1px" borderRadius="4px" p={3} borderColor="gray.200">
        <Text fontSize="sm">ID: {id}</Text>
        <Flex justifyContent="center">
          <Text fontSize="sm">Start: {start}s</Text>
        </Flex>
        <Text fontSize="sm">Created At: {convertTZ(timestamp, { showTimeZone: true })}</Text>

        <Text mt={2} fontWeight="bold">
          {title || ''}
        </Text>
        <Badge mt={2} px={3} fontSize="lg" rounded="md" bg="yellow.200" fontWeight="bold">
          {type}
        </Badge>
        <Divider />
        <Button
          isLoading={loading}
          onClick={() => deleteQuestion(id)}
          mt={2}
          bg="red.500"
          color="white"
          fontWeight="bold"
          w="100%"
        >
          Delete Reward
        </Button>
      </Box>
    );
  };

  const renderGame = (item = {}) => {
    const { timestamp, start, end, id, url } = item;

    return (
      <Box key={id} border="1px" borderRadius="4px" p={3} borderColor="gray.200">
        <Text fontSize="sm">ID: {id}</Text>
        <Flex justifyContent="center">
          <Text fontSize="sm">Start: {start}s</Text>
          <Text ml={2} fontSize="sm">
            End: {end}s
          </Text>
        </Flex>
        <Text fontSize="sm">Created At: {convertTZ(timestamp, { showTimeZone: true })}</Text>

        <a style={{ textDecoration: 'underline' }} href={url} target="_blank">
          {url}
        </a>
        <Divider />
        <Button
          isLoading={loading}
          onClick={() => deleteQuestion(id)}
          mt={2}
          bg="red.500"
          color="white"
          fontWeight="bold"
          w="100%"
        >
          Delete Game
        </Button>
      </Box>
    );
  };

  const validateUrl = value => {
    let error;
    if (!value) {
      error = `URL required`;
    } else if (!value.startsWith('https://')) error = 'Must be valid URL';
    return error || true;
  };

  const renderPresentationModal = () => (
    <Modal isOpen={presentationModal} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Presentation</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(async data => await onCreatePresentation(data))}>
            <FormControl mt={4} isInvalid={errors.slides_presentation}>
              <Text fontWeight="bold">Presentation URL</Text>
              <Input
                name="slides_presentation"
                placeholder="CDN URL"
                borderRadius="4px"
                height="50px"
                textAlign="center"
                fontSize="lg"
                ref={register({
                  validate: validateUrl,
                })}
              />
              <FormErrorMessage>
                {errors.slides_presentation && errors.slides_presentation.message}
              </FormErrorMessage>
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            fontWeight="medium"
            mr={3}
            onClick={() => setPresentationModal(false)}
          >
            Cancel
          </Button>
          <Button
            isLoading={formState.isSubmitting}
            bg="purple.500"
            fontWeight="medium"
            color="white"
            onClick={handleSubmit(async data => await onCreatePresentation(data))}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const renderTimeSlotModal = () => (
    <Modal isOpen={timeSlotModal} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Time Slot</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(async data => await onCreateTimeSlot(data))}>
            <FormControl mt={4} isInvalid={errors.teacher}>
              <Text fontWeight="bold">Teacher</Text>
              {teacherOptions.length > 0 && (
                <Select
                  name="teacher_id"
                  ref={register({
                    validate: validateId,
                  })}
                >
                  {teacherOptions.map(r => {
                    return (
                      <option key={r.id} value={r.id}>
                        {r.title}
                      </option>
                    );
                  })}
                </Select>
              )}
              <FormErrorMessage>{errors.teacher && errors.teacher.message}</FormErrorMessage>
            </FormControl>
            <FormControl mt={4} isInvalid={errors.date}>
              <Text fontWeight="bold">Date</Text>
              <Input
                name="date"
                placeholder="YYYY-MM-DD HH:SS (24 hour time)"
                borderRadius="4px"
                height="50px"
                textAlign="center"
                fontSize="lg"
                ref={register({
                  validate: validateId,
                })}
              />
              <FormErrorMessage>{errors.date && errors.date.message}</FormErrorMessage>
            </FormControl>
            <FormControl mt={4} isInvalid={errors.timezone}>
              <Text fontWeight="bold">Timezone</Text>
              <Select
                name="timezone"
                ref={register({
                  validate: validateId,
                })}
              >
                <option value={'America/Los_Angeles'}>Pacific</option>
                <option value={'America/Chicago'}>Central</option>
                <option value={'America/New_York'}>Eastern</option>
              </Select>
              <FormErrorMessage>{errors.timezone && errors.timezone.message}</FormErrorMessage>
            </FormControl>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="ghost"
            fontWeight="medium"
            mr={3}
            onClick={() => setTimeSlotModal(false)}
          >
            Cancel
          </Button>
          <Button
            isLoading={formState.isSubmitting}
            bg="purple.500"
            fontWeight="medium"
            color="white"
            onClick={handleSubmit(async data => await onCreateTimeSlot(data))}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const renderRewardModal = () => (
    <Modal
      size="lg"
      isOpen={rewardModal}
      onClose={() => setRewardModal(null)}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Reward</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(async data => await onCreateReward(data))}>
            <FormControl mt={4} isInvalid={errors.title}>
              <Text fontWeight="bold">Prompt</Text>
              <Input
                name="title"
                placeholder="Optional Prompt"
                borderRadius="4px"
                height="50px"
                textAlign="center"
                fontSize="lg"
                ref={register()}
              />
              <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
            </FormControl>
            <FormControl mt={4} isInvalid={errors.start}>
              <Text fontWeight="bold">
                Start <span style={{ fontWeight: 'normal' }}></span>
              </Text>
              <Input
                name="start"
                placeholder="Start Time"
                borderRadius="4px"
                height="50px"
                textAlign="center"
                fontSize="lg"
                ref={register({
                  validate: validateId,
                })}
              />
              <FormErrorMessage>{errors.start && errors.start.message}</FormErrorMessage>
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" fontWeight="medium" mr={3} onClick={() => setRewardModal(false)}>
            Cancel
          </Button>
          <Button
            isLoading={formState.isSubmitting}
            bg="purple.500"
            fontWeight="medium"
            color="white"
            onClick={handleSubmit(async data => await onCreateReward(data))}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const renderPicturePollModal = () => (
    <Modal
      size="lg"
      isOpen={picturePollModal}
      onClose={() => setPicturePollModal(false)}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Picture Poll</ModalHeader>
        <ModalBody>
          <form
            onSubmit={handleSubmit(
              async data => await onCreateQuestion({ poll_type: 'picture', ...data })
            )}
          >
            <FormControl mt={4} isInvalid={errors.question}>
              <Text fontWeight="bold">Poll Question</Text>
              <Input
                name="title"
                placeholder="Poll Question"
                borderRadius="4px"
                height="50px"
                textAlign="center"
                fontSize="lg"
                ref={register({
                  validate: validateId,
                })}
              />
              <FormErrorMessage>{errors.question && errors.question.message}</FormErrorMessage>
            </FormControl>
            <Flex>
              <FormControl mt={4} isInvalid={errors.start}>
                <Text fontWeight="bold">
                  Start <span style={{ fontWeight: 'normal' }}></span>
                </Text>
                <Input
                  name="start"
                  placeholder="Start Time"
                  borderRadius="4px"
                  height="50px"
                  textAlign="center"
                  fontSize="lg"
                  ref={register({
                    validate: validateId,
                  })}
                />
                <FormErrorMessage>{errors.start && errors.start.message}</FormErrorMessage>
              </FormControl>
              <FormControl ml={2} mt={4} isInvalid={errors.reveal}>
                <Text fontWeight="bold">
                  Reveal <span style={{ fontWeight: 'normal' }}></span>
                </Text>{' '}
                <Input
                  name="reveal"
                  placeholder="Reveal Time"
                  borderRadius="4px"
                  height="50px"
                  textAlign="center"
                  fontSize="lg"
                  ref={register({
                    validate: validateId,
                  })}
                />
                <FormErrorMessage>{errors.reveal && errors.reveal.message}</FormErrorMessage>
              </FormControl>
              <FormControl ml={2} mt={4} isInvalid={errors.end}>
                <Text fontWeight="bold">
                  End <span style={{ fontWeight: 'normal' }}></span>
                </Text>{' '}
                <Input
                  name="end"
                  placeholder="End Time"
                  borderRadius="4px"
                  height="50px"
                  textAlign="center"
                  fontSize="lg"
                  ref={register({
                    validate: validateId,
                  })}
                />
                <FormErrorMessage>{errors.end && errors.end.message}</FormErrorMessage>
              </FormControl>
            </Flex>

            <FormControl mt={4}>
              <Box d="flex" justifyContent="space-between">
                <Text fontWeight="bold">Picture 1</Text>

                <Box d="flex" alignItems="center">
                  <Text fontSize="xs">Correct Answer</Text>
                  <Switch
                    size="md"
                    color="green"
                    ml={2}
                    pt={1}
                    isChecked={correctAnswerIndex === 0}
                    onChange={e => setCorrectAnswerIndex(correctAnswerIndex === 0 ? null : 0)}
                  />
                </Box>
              </Box>
              <Input
                name="answer1"
                placeholder="Picture 1 Title"
                borderRadius="4px"
                height="50px"
                textAlign="center"
                fontSize="lg"
                ref={register({
                  validate: validateId,
                })}
              />
              <Box mt={2} d="flex" justifyContent="space-between">
                {renderPollUploader(0)}
              </Box>
            </FormControl>
            <FormControl mt={4}>
              <Box d="flex" justifyContent="space-between">
                <Text fontWeight="bold">Picture 2</Text>
                <Box d="flex" alignItems="center">
                  <Text fontSize="xs">Correct Answer</Text>
                  <Switch
                    size="md"
                    color="green"
                    ml={2}
                    pt={1}
                    isChecked={correctAnswerIndex === 1}
                    onChange={e => setCorrectAnswerIndex(correctAnswerIndex === 1 ? null : 1)}
                  />
                </Box>
              </Box>
              <Input
                name="answer2"
                placeholder="Picture 2 Title"
                borderRadius="4px"
                height="50px"
                textAlign="center"
                fontSize="lg"
                ref={register({
                  validate: validateId,
                })}
              />
              <Box mt={2} d="flex" justifyContent="space-between">
                {renderPollUploader(1)}
              </Box>
            </FormControl>
            <FormControl mt={4}>
              <Box d="flex" justifyContent="space-between">
                <Text fontWeight="bold">Picture 3</Text>
                <Box d="flex" alignItems="center">
                  <Text fontSize="xs">Correct Answer</Text>
                  <Switch
                    size="md"
                    color="green"
                    ml={2}
                    pt={1}
                    isChecked={correctAnswerIndex === 2}
                    onChange={e => setCorrectAnswerIndex(correctAnswerIndex === 2 ? null : 2)}
                  />
                </Box>
              </Box>
              <Input
                name="answer3"
                placeholder="Picture 3 Title"
                borderRadius="4px"
                height="50px"
                textAlign="center"
                fontSize="lg"
                ref={register()}
              />
              <Box mt={2} d="flex" justifyContent="space-between">
                {renderPollUploader(2)}
              </Box>
            </FormControl>
            <FormControl mt={4}>
              <Box d="flex" justifyContent="space-between">
                <Text fontWeight="bold">Picture 4</Text>
                <Box d="flex" alignItems="center">
                  <Text fontSize="xs">Correct Answer</Text>
                  <Switch
                    size="md"
                    color="green"
                    ml={2}
                    pt={1}
                    isChecked={correctAnswerIndex === 3}
                    onChange={e => setCorrectAnswerIndex(correctAnswerIndex === 3 ? null : 3)}
                  />
                </Box>
              </Box>
              <Input
                name="answer4"
                placeholder="Picture 4 Title"
                borderRadius="4px"
                height="50px"
                textAlign="center"
                fontSize="lg"
                ref={register()}
              />
              <Box mt={2} d="flex" justifyContent="space-between">
                {renderPollUploader(3)}
              </Box>
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            fontWeight="medium"
            mr={3}
            onClick={() => setPicturePollModal(false)}
          >
            Cancel
          </Button>
          <Button
            isLoading={formState.isSubmitting}
            bg="purple.500"
            fontWeight="medium"
            color="white"
            onClick={handleSubmit(
              async data => await onCreateQuestion({ poll_type: 'picture', ...data })
            )}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const renderQuestionModal = () => (
    <Modal size="lg" isOpen={questionModal} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Poll</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(async data => await onCreateQuestion(data))}>
            <FormControl mt={4} isInvalid={errors.question}>
              <Text fontWeight="bold">Poll Text</Text>
              <Input
                name="title"
                placeholder="Question Text"
                borderRadius="4px"
                height="50px"
                textAlign="center"
                fontSize="lg"
                ref={register({
                  validate: validateId,
                })}
              />
              <FormErrorMessage>{errors.question && errors.question.message}</FormErrorMessage>
            </FormControl>
            <Flex>
              <FormControl mt={4} isInvalid={errors.start}>
                <Text fontWeight="bold">
                  Start <span style={{ fontWeight: 'normal' }}></span>
                </Text>
                <Input
                  name="start"
                  placeholder="Start Time"
                  borderRadius="4px"
                  height="50px"
                  textAlign="center"
                  fontSize="lg"
                  ref={register({
                    validate: validateId,
                  })}
                />
                <FormErrorMessage>{errors.start && errors.start.message}</FormErrorMessage>
              </FormControl>
              <FormControl ml={2} mt={4} isInvalid={errors.reveal}>
                <Text fontWeight="bold">
                  Reveal <span style={{ fontWeight: 'normal' }}></span>
                </Text>{' '}
                <Input
                  name="reveal"
                  placeholder="Reveal Time"
                  borderRadius="4px"
                  height="50px"
                  textAlign="center"
                  fontSize="lg"
                  ref={register({
                    validate: validateId,
                  })}
                />
                <FormErrorMessage>{errors.reveal && errors.reveal.message}</FormErrorMessage>
              </FormControl>
              <FormControl ml={2} mt={4} isInvalid={errors.end}>
                <Text fontWeight="bold">
                  End <span style={{ fontWeight: 'normal' }}></span>
                </Text>{' '}
                <Input
                  name="end"
                  placeholder="End Time"
                  borderRadius="4px"
                  height="50px"
                  textAlign="center"
                  fontSize="lg"
                  ref={register({
                    validate: validateId,
                  })}
                />
                <FormErrorMessage>{errors.end && errors.end.message}</FormErrorMessage>
              </FormControl>
            </Flex>

            <FormControl mt={4} isInvalid={errors.correctAnswer}>
              <Box d="flex" justifyContent="space-between">
                <Text fontWeight="bold">Answer 1</Text>
                <Box d="flex" alignItems="center">
                  <Text fontSize="xs">Correct Answer</Text>
                  <Switch
                    size="md"
                    color="green"
                    ml={2}
                    pt={1}
                    isChecked={correctAnswerIndex === 0}
                    onChange={e => setCorrectAnswerIndex(correctAnswerIndex === 0 ? null : 0)}
                  />
                </Box>
              </Box>
              <Box d="flex" justifyContent="space-between">
                <Input
                  name="answer1"
                  placeholder="Answer 1"
                  borderRadius="4px"
                  height="50px"
                  textAlign="center"
                  fontSize="lg"
                  ref={register({
                    validate: validateId,
                  })}
                />
                <InputGroup>
                  <Input
                    ml={2}
                    name="share1"
                    placeholder="Share %"
                    height="50px"
                    textAlign="center"
                    fontSize="lg"
                    type="number"
                    ref={register()}
                  />
                  <InputRightAddon h="100%" children="%" />
                </InputGroup>
                <FormErrorMessage>{errors.answer1 && errors.answer1.message}</FormErrorMessage>
              </Box>
            </FormControl>
            <FormControl mt={4} isInvalid={errors.question}>
              <Box d="flex" justifyContent="space-between">
                <Text fontWeight="bold">Answer 2</Text>
                <Box d="flex" alignItems="center">
                  <Text fontSize="xs">Correct Answer</Text>
                  <Switch
                    size="md"
                    color="green"
                    ml={2}
                    pt={1}
                    isChecked={correctAnswerIndex === 1}
                    onChange={e => setCorrectAnswerIndex(correctAnswerIndex === 1 ? null : 1)}
                  />
                </Box>
              </Box>
              <Box d="flex" justifyContent="space-between">
                <Input
                  name="answer2"
                  placeholder="Answer 2"
                  borderRadius="4px"
                  height="50px"
                  textAlign="center"
                  fontSize="lg"
                  ref={register({
                    validate: validateId,
                  })}
                />
                <InputGroup>
                  <Input
                    ml={2}
                    name="share2"
                    placeholder="Share %"
                    height="50px"
                    textAlign="center"
                    fontSize="lg"
                    type="number"
                    ref={register()}
                  />
                  <InputRightAddon h="100%" children="%" />
                </InputGroup>
                <FormErrorMessage>{errors.answer2 && errors.answer2.message}</FormErrorMessage>
              </Box>
            </FormControl>
            <FormControl mt={4} isInvalid={errors.question}>
              <Box d="flex" justifyContent="space-between">
                <Text fontWeight="bold">Answer 3</Text>
                <Box d="flex" alignItems="center">
                  <Text fontSize="xs">Correct Answer</Text>
                  <Switch
                    size="md"
                    color="green"
                    ml={2}
                    pt={1}
                    isChecked={correctAnswerIndex === 2}
                    onChange={e => setCorrectAnswerIndex(correctAnswerIndex === 2 ? null : 2)}
                  />
                </Box>
              </Box>
              <Box d="flex" justifyContent="space-between">
                <Input
                  name="answer3"
                  placeholder="Answer 3"
                  borderRadius="4px"
                  height="50px"
                  textAlign="center"
                  fontSize="lg"
                  ref={register()}
                />
                <InputGroup>
                  <Input
                    ml={2}
                    name="share3"
                    placeholder="Share %"
                    height="50px"
                    textAlign="center"
                    fontSize="lg"
                    type="number"
                    ref={register()}
                  />
                  <InputRightAddon h="100%" children="%" />
                </InputGroup>
                <FormErrorMessage>{errors.answer3 && errors.answer3.message}</FormErrorMessage>
              </Box>
            </FormControl>
            <FormControl mt={4} isInvalid={errors.question}>
              <Box d="flex" justifyContent="space-between">
                <Text fontWeight="bold">Answer 4</Text>
                <Box d="flex" alignItems="center">
                  <Text fontSize="xs">Correct Answer</Text>
                  <Switch
                    size="md"
                    color="green"
                    ml={2}
                    pt={1}
                    isChecked={correctAnswerIndex === 3}
                    onChange={e => setCorrectAnswerIndex(correctAnswerIndex === 3 ? null : 3)}
                  />
                </Box>
              </Box>
              <Box d="flex" justifyContent="space-between">
                <Input
                  name="answer4"
                  placeholder="Answer 4"
                  borderRadius="4px"
                  height="50px"
                  textAlign="center"
                  fontSize="lg"
                  ref={register()}
                />
                <InputGroup>
                  <Input
                    ml={2}
                    name="share4"
                    placeholder="Share %"
                    height="50px"
                    textAlign="center"
                    fontSize="lg"
                    type="number"
                    ref={register()}
                  />
                  <InputRightAddon h="100%" children="%" />
                </InputGroup>
              </Box>
            </FormControl>
            {/*<FormControl mt={4}>
              <Box d="flex" justifyContent="flex-end" alignItems="center">
                <Text fontWeight="bold">Hide Answer Options (A,B,C)</Text>
                <Switch
                  size="md"
                  color="green"
                  ml={2}
                  pt={1}
                  onChange={e => setHideAnswerOptions(e.target.checked)}
                />
              </Box>
                  </FormControl>*/}
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            fontWeight="medium"
            mr={3}
            onClick={() => setQuestionModal(false)}
          >
            Cancel
          </Button>
          <Button
            isLoading={formState.isSubmitting}
            bg="purple.500"
            fontWeight="medium"
            color="white"
            onClick={handleSubmit(async data => await onCreateQuestion(data))}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const renderGameModal = () => (
    <Modal isOpen={gameModal} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Game</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(async data => await onCreateGame(data))}>
            <Flex>
              <FormControl mt={4} isInvalid={errors.start}>
                <Text fontWeight="bold">
                  Start <span style={{ fontWeight: 'normal' }}></span>
                </Text>{' '}
                <Input
                  name="start"
                  placeholder="Start Time"
                  borderRadius="4px"
                  height="50px"
                  textAlign="center"
                  fontSize="lg"
                  onChange={e => setGameStart(e.target.value)}
                />
                <FormErrorMessage>{errors.start && errors.start.message}</FormErrorMessage>
              </FormControl>
              <FormControl ml={2} mt={4} isInvalid={errors.end}>
                <Text fontWeight="bold">
                  End <span style={{ fontWeight: 'normal' }}></span>
                </Text>{' '}
                <Input
                  name="end"
                  placeholder="End Time"
                  borderRadius="4px"
                  height="50px"
                  textAlign="center"
                  fontSize="lg"
                  onChange={e => setGameEnd(e.target.value)}
                />
                <FormErrorMessage>{errors.end && errors.end.message}</FormErrorMessage>
              </FormControl>
            </Flex>

            <FormControl mt={4} isInvalid={errors.url}>
              <Text fontWeight="bold">Upload .zip file</Text>
              {renderGameUploader()}
              {gameUploadProgress > 0 && (
                <Text fontStyle="lg" fontWeight="bold">
                  Uploading: {gameUploadProgress}% {gameUploadProgress === 100 && <span>✅</span>}
                </Text>
              )}
              {gameUploadProgress === 100 && <Text>Processing file....</Text>}
              <FormErrorMessage>{errors.url && errors.url.message}</FormErrorMessage>
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" fontWeight="medium" mr={3} onClick={() => setGameModal(false)}>
            Cancel
          </Button>
          <Button
            isLoading={formState.isSubmitting}
            bg="purple.500"
            fontWeight="medium"
            color="white"
            onClick={handleSubmit(async data => await onCreateGame(data))}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const onUploadError = err => {
    console.log('FINISHED');
    showErrorToast(toast, err ? err.message : 'Could not upload');
  };
  const onUploadFinish = () => {
    console.log('FINISHED');
    setTimeout(() => {
      fetchItem();
    }, 10000);
  };

  const onUploadGameError = err => {
    console.log('FINISHED');
    showErrorToast(toast, err ? err.message : 'Could not upload');
  };
  const onUploadGameFinish = () => {
    console.log('FINISHED');
    setTimeout(() => {
      fetchItem();
    }, 5000);
  };
  const onSlidesUploadProgress = p => {
    console.log('p: ', p);
    setSlidesUploadProgress(p);
  };

  const fetchPresentationUploadUrl = (file, callback) => {
    actions.fetchSignedUrl(class_id, 'vods', file, null, null, callback);
  };

  const fetchPollUploadUrl = (file, callback, index) => {
    actions.fetchSignedUrl(class_id, 'polls', file, null, null, r => {
      setPollUploadUrls(prevUrls => {
        prevUrls[index] = r.signedUrl;
        return prevUrls;
      });
      callback(r);
    });
  };

  const fetchGameUploadUrl = (file, callback) => {
    console.log('Game Start: ', gameStart);
    console.log('Game End: ', gameEnd);
    actions.fetchSignedUrl(class_id, 'games', file, gameStart, gameEnd, callback);
  };

  const fetchVirtualBackgrounUploadUrl = (file, callback) => {
    actions.fetchSignedUrl(class_id, 'backgrounds', file, null, null, callback);
  };

  const fetchPosterUploadUrl = (file, callback) => {
    actions.fetchSignedUrl(class_id, 'posters', file, null, null, callback);
  };

  const renderUploader = () => (
    <Flex w="100%" justifyContent="center">
      <ReactS3Uploader
        getSignedUrl={fetchPresentationUploadUrl}
        accept="video/mp4"
        onProgress={onSlidesUploadProgress}
        onError={onUploadError}
        onFinish={onUploadFinish}
        uploadRequestHeaders={{ 'Content-Type': 'video/mp4' }} // this is the default
        contentDisposition="auto"
        scrubFilename={filename => filename.replace(/[^\w\d_\-.]+/gi, '')}
        autoUpload={true}
      />
    </Flex>
  );

  const renderPollUploader = index => (
    <Stack isInline w="100%">
      <ReactS3Uploader
        getSignedUrl={(file, cb) => fetchPollUploadUrl(file, cb, index)}
        accept="image/jpeg"
        onProgress={p =>
          setPollUploadProgress(prevProgress => {
            console.log(p);
            prevProgress[index] = p;
            return [...prevProgress];
          })
        }
        onError={onUploadError}
        onFinish={onUploadFinish}
        uploadRequestHeaders={{ 'Content-Type': 'image/jpeg' }} // this is the default
        contentDisposition="auto"
        scrubFilename={filename => filename.replace(/[^\w\d_\-.]+/gi, '')}
        autoUpload={true}
      />
      <Text>
        Upload: {pollUploadProgress[index] === 100 ? '✅' : `${pollUploadProgress[index]}%`}
      </Text>
    </Stack>
  );

  const renderVirtualBackgroundUploader = () => (
    <Flex w="100%" justifyContent="center">
      <ReactS3Uploader
        getSignedUrl={fetchVirtualBackgrounUploadUrl}
        accept="image/jpeg"
        onProgress={p => setVirtualBackgroundUploadProgress(p)}
        onError={onUploadError}
        onFinish={onUploadFinish}
        uploadRequestHeaders={{ 'Content-Type': 'image/jpeg' }} // this is the default
        contentDisposition="auto"
        scrubFilename={filename => filename.replace(/[^\w\d_\-.]+/gi, '')}
        autoUpload={true}
      />
    </Flex>
  );

  const renderPosterUploader = () => (
    <Flex w="100%" justifyContent="center">
      <ReactS3Uploader
        getSignedUrl={fetchPosterUploadUrl}
        accept="image/jpeg"
        onProgress={p => setPosterUploadProgress(p)}
        onError={onUploadError}
        onFinish={onUploadFinish}
        uploadRequestHeaders={{ 'Content-Type': 'image/jpeg' }} // this is the default
        contentDisposition="auto"
        scrubFilename={filename => filename.replace(/[^\w\d_\-.]+/gi, '')}
        autoUpload={true}
      />
    </Flex>
  );

  const renderGameUploader = () => (
    <Flex w="100%" justifyContent="center">
      <ReactS3Uploader
        getSignedUrl={fetchGameUploadUrl}
        accept="application/*"
        onProgress={setGameUploadProgress}
        onError={onUploadGameError}
        onFinish={onUploadGameFinish}
        uploadRequestHeaders={{ 'Content-Type': 'application/zip' }} // this is the default
        contentDisposition="auto"
        scrubFilename={filename => filename.replace(/[^\w\d_\-.]+/gi, '')}
        autoUpload={false}
        ref={gameUploadRef}
      />
    </Flex>
  );

  const renderLearningGoalInput = () => (
    <Box>
      <Stack>
        <Text mt={4} fontWeight="bold" fontSize="2xl">
          Class Learning Goal
        </Text>

        <Textarea
          ref={textAreaRef}
          placeholder="This can only be 58 characters long"
          onChange={onLearningGoalChange}
        />

        {learningGoal.length > 58 && (
          <Box bg="gray.300" rounded="full" p={2}>
            <Text textAlign="left" color="red.400" fontWeight="bold">
              Bruh, only 58 characters allowed 🙄
            </Text>
          </Box>
        )}

        <Button
          isLoading={submittingLearningGoal}
          isDisabled={learningGoal.length > 58 || learningGoal.length === 0}
          fontWeight="bold"
          bg="gray.500"
          color="white"
          h="50px"
          fontSize="lg"
          style={{ textShadow: '0 -1px 0 rgba(0, 0, 0, .35)' }}
          onClick={submitLearningGoal}
        >
          <Icon mr={2} name="edit" />
          Update Learning Goal
        </Button>

        {zipClass && zipClass.learning_goal && (
          <Box border="1px" borderRadius="4px" p={3} borderColor="gray.200">
            <Text textAlign="left" fontWeight="bold" fontSize="md">
              {zipClass.learning_goal}
            </Text>
          </Box>
        )}
      </Stack>
      <Divider />
    </Box>
  );

  const onDurationUpdate = async () => {
    setLoading(true);
    try {
      await actions.updateClassDuration(class_id);
      await fetchItem();
    } catch (err) {
      showErrorToast(toast, err.message);
    }
    setLoading(false);
  };

  return (
    <Box maxW="md" mx="auto" mt={5} p={5}>
      {renderPresentationModal()}
      {renderTimeSlotModal()}
      {renderQuestionModal()}
      {renderGameModal()}
      {renderRewardModal()}
      {renderPicturePollModal()}

      <Stack spacing={4}>
        <Box>
          <Text mt={4} fontWeight="bold" fontSize="2xl">
            {zipClass.title}
          </Text>
          <Stack justify="center" isInline>
            <Text mt={4}>{zipClass.subject_icon}</Text>
            <Text mt={4}>{zipClass.subject}</Text>
          </Stack>
          <Stack justify="center" alignItems="center" isInline>
            <Text mt={4}>Order: {zipClass.hasOwnProperty('order') ? zipClass.order : 'N/A'}</Text>
            <Text mt={4}>Duration: {zipClass.duration} mins</Text>
            <Button h="30px" w="30px" isDisabled={loading} onClick={onDurationUpdate}>
              <Icon name="repeat-clock" />
            </Button>
          </Stack>
          {zipClass.teacher && (
            <Text mt={4}>
              Teacher: {`${zipClass.teacher.first_name} ${zipClass.teacher.last_name}`}
            </Text>
          )}
          {zipClass.description && <Text mt={4}>{zipClass.description}</Text>}

          <Divider />

          <Text mt={4} fontWeight="bold" fontSize="2xl">
            Poster
          </Text>
          <Divider />
          {loading && !zipClass.classes && (
            <Box d="flex" my={4} justifyContent="center">
              <Spinner color="purple.500" />
            </Box>
          )}
          {!loading && (
            <Stack spacing={5}>
              {zipClass.poster_url && !zipClass.poster_url.endsWith('.mp4') && (
                <Stack isInline justifyContent="center">
                  <Image maxW="400px" src={zipClass.poster_url.split('?').shift()} />
                </Stack>
              )}
              {zipClass.poster_url && zipClass.poster_url.endsWith('.mp4') && (
                <Text mt={2} fontStyle="lg" fontWeight="bold">
                  {zipClass.poster_url ? (
                    <a
                      style={{ textDecoration: 'underline' }}
                      href={zipClass.poster_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {zipClass.poster_url.split('?').shift()}
                    </a>
                  ) : (
                    '⚠️ No Poster Uploaded'
                  )}
                </Text>
              )}

              {renderPosterUploader()}
              {posterUploadProgress > 0 && (
                <Text fontStyle="lg" fontWeight="bold">
                  Uploading: {posterUploadProgress}%{' '}
                  {posterUploadProgress === 100 && <span>✅</span>}
                </Text>
              )}
              {posterUploadProgress === 100 && <Text>Processing file....</Text>}
            </Stack>
          )}
          <Divider />
        </Box>
        <Box>
          <Text mt={4} fontWeight="bold" fontSize="2xl">
            VOD
          </Text>
          <Divider />
          {loading && !zipClass.classes && (
            <Box d="flex" my={4} justifyContent="center">
              <Spinner color="purple.500" />
            </Box>
          )}
          {!loading && (
            <Stack spacing={5}>
              <Text mt={2} fontStyle="lg" fontWeight="bold">
                {zipClass.vod_url ? (
                  <Box>
                    <Text>{zipClass.vod_status}</Text>
                    <a
                      style={{ textDecoration: 'underline' }}
                      href={zipClass.vod_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {zipClass.vod_url.split('?').shift()}
                    </a>
                  </Box>
                ) : (
                  '⚠️ No VOD Uploaded'
                )}
              </Text>
              {renderUploader()}
              {slidesUploadProgress > 0 && (
                <Text fontStyle="lg" fontWeight="bold">
                  Uploading: {slidesUploadProgress}%{' '}
                  {slidesUploadProgress === 100 && <span>✅</span>}
                </Text>
              )}
              {slidesUploadProgress === 100 && <Text>Processing file....</Text>}
            </Stack>
          )}
          <Divider />
        </Box>

        <Box>
          <Text mt={4} fontWeight="bold" fontSize="2xl">
            Virtual Background
          </Text>
          <Divider />
          {loading && !zipClass.classes && (
            <Box d="flex" my={4} justifyContent="center">
              <Spinner color="purple.500" />
            </Box>
          )}
          {!loading && (
            <Stack spacing={5}>
              <Text mt={2} fontStyle="lg" fontWeight="bold">
                {zipClass.virtual_bg_url ? (
                  <Stack isInline justifyContent="center">
                    <Image maxW="400px" src={zipClass.virtual_bg_url.split('?').shift()} />
                  </Stack>
                ) : (
                  '⚠️ No Virutal Background Uploaded'
                )}
              </Text>
              {renderVirtualBackgroundUploader()}
              {virtualBackgroundUploadProgress > 0 && (
                <Text fontStyle="lg" fontWeight="bold">
                  Uploading: {virtualBackgroundUploadProgress}%{' '}
                  {virtualBackgroundUploadProgress === 100 && <span>✅</span>}
                </Text>
              )}
              {virtualBackgroundUploadProgress === 100 && <Text>Processing file....</Text>}
            </Stack>
          )}
          <Divider />
        </Box>

        {renderLearningGoalInput(0)}

        <Box>
          <Text mt={4} fontWeight="bold" fontSize="2xl">
            Polls
          </Text>
          <Divider />
          {loading && !zipClass.classes && (
            <Box d="flex" my={4} justifyContent="center">
              <Spinner color="purple.500" />
            </Box>
          )}
          {!loading && (
            <Stack mt={2} spacing={2}>
              <Button
                isLoading={loading}
                fontWeight="bold"
                bg="gray.500"
                color="white"
                h="50px"
                fontSize="lg"
                style={{ textShadow: '0 -1px 0 rgba(0, 0, 0, .35)' }}
                onClick={() => setQuestionModal(true)}
              >
                <Icon mr={2} name="add" />
                Create Text Poll
              </Button>
              <Button
                isLoading={loading}
                fontWeight="bold"
                bg="gray.500"
                color="white"
                h="50px"
                fontSize="lg"
                style={{ textShadow: '0 -1px 0 rgba(0, 0, 0, .35)' }}
                onClick={() => setPicturePollModal(true)}
              >
                <Icon mr={2} name="add" />
                Create Picture Poll
              </Button>
              {zipClass.questions && zipClass.questions.map(renderPoll)}
            </Stack>
          )}
          <Divider />
        </Box>
        <Box>
          <Text mt={4} fontWeight="bold" fontSize="2xl">
            Rewards
          </Text>
          <Divider />
          {loading && (
            <Box d="flex" my={4} justifyContent="center">
              <Spinner color="purple.500" />
            </Box>
          )}
          {!loading && (
            <Stack mt={2} spacing={2}>
              <Button
                isLoading={loading}
                fontWeight="bold"
                bg="gray.500"
                color="white"
                h="50px"
                fontSize="lg"
                style={{ textShadow: '0 -1px 0 rgba(0, 0, 0, .35)' }}
                onClick={() => setRewardModal(true)}
              >
                <Icon mr={2} name="add" />
                Create Reward
              </Button>
              {zipClass.rewards && zipClass.rewards.map(renderReward)}
            </Stack>
          )}
          <Divider />
        </Box>
      </Stack>
    </Box>
  );
};

export default ZipClass;
